.header {
    height: 80px;
    background: inherit;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 32px;
    -webkit-transition: background-color 0.5s ease-out;
    -moz-transition: background-color 0.5s ease-out;
    -o-transition: background-color 0.5s ease-out;
    transition: background-color 0.5s ease-out;
}

.bgWhite {
    background: white;
}

.header>div {
    display: flex;
    align-items: center;
}

.header a {
    font-size: 18px;
    font-weight: 500;
}

.header .screeningButton {
    width: auto;
    padding: 0 20px;
    margin: 0;
    margin-left: 30px;
}

.header .profileButton {
    margin-left: 20px;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
    padding: 0;
}

.header .profileButton>div {
    background: var(--lighter-grey-color);
    border: 1px solid var(--light-grey-color);
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}

.popoverContent button {
    width: 250px;
    height: 60px;
    padding: 0 20px;
    display: block; 
    border: none;
    background: none;
    text-align: left;
    cursor: pointer;

    font-family: 'Montserrat';
    font-size: 16px;

    border-bottom: 1px solid var(--lighter-grey-color);
    transition: all 0.3s;
}

.popoverContent button:hover {
   padding-left: 25px;
}

.popoverContent button:last-of-type {
    border-bottom: none;
}

.mobileOnly {
    display: none !important;
}

@media (max-width: 900px) {
    .header {
        padding: 0 20px;
    }

    .header .profileButton>div {
        margin-right: 5px;
    }

    .header .logo {
        height: 20px;
    }

    .desktopOnly {
        display: none;
    }

    .header .screeningButton {
        display: none;
    }
    .mobileOnly {
        display: block !important;
    }
}
