.accordionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.accordionHeader h3 {
    margin: 0;
}

.accordionContent p {
    margin: 0;
}

.accordionContentEnter {
    opacity: 0;
    height: 0;
}

.accordionContentEnterActive {
    opacity: 1;
    height: auto;
    transition: opacity 500ms ease-out, height 500ms ease-out;
}

.enter {
    max-height: 0;
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
}

.enterActive {
    max-height: 454px;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    transition: max-height 0.5s, padding-top 0.5s, padding-bottom 0.5s;
    overflow: hidden;
}

.enterDone {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.exit {
    max-height: 454px;
    overflow: hidden;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.exitActive {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    transition: max-height 0.5s, padding-top 0.5s, padding-bottom 0.5s;
    overflow: hidden;
}