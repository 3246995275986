.loader {
  margin: 56px 48vw 0px 48vw
}

/* <ActiveSection /> */

.status.active p {
  max-width: 500px;
  margin-bottom: 24px;
}

.status.active button.stopBtn {
  color: #A8071A;
  border-color: #A8071A;
  max-width: 230px;
}

.status.active button.stopBtn:hover,
.status.active button.stopBtn:focus {
  background-color: transparent;
  border: 2px solid #A8071A;
}

.status.active button.stopBtn:hover {
  opacity: 0.4;
}

.status.active h3 {
  margin-top: 56px;
}

/* <InactiveSection /> */

.status.inactive p {
  max-width: 556px;
  margin-bottom: 40px;
}

.status.inactive button {
  max-width: 230px;
}

.status.inactive p:last-child {
  font-size: 12px;
}

.status.inactive p:last-child {
  font-size: 12px;
}
