/* Root container */
.confirmationContainer {
    font-size: 14px;
    font-family: "Poppins";
    line-height: 1.5;

    --spacing--large: 50px;
    --spacing--small: 10px;

    display: flex;
    flex-direction: column;
    gap: var(--spacing--large);
}

.link {
    color: #000000;
    text-decoration: underline !important;
    font-size: 1em !important;
    /* Override default size in index.css */
}



.confirmationHeader__icon {
    margin: var(--spacing--large) auto;
    width: 80px;
    height: 80px;
}

.confirmationHeader__title {
    font-size: 32px;
    text-align: center;
    font-weight: 500;
    margin-bottom: var(--spacing--small);
}

.confirmationHeader__subtitle {
    text-align: center;
    margin: var(--spacing--small) auto;
}

/* Appointment details */
.appointmentDetails {
    margin: 0 auto;

    display: flex;
    align-items: center;
    gap: 10px;
}

.appointmentDetails+.appointmentDetails {
    margin-top: 2em;
}

.appointmentDetails__icon {
    width: 30px;
    height: 30px;
}

.appointmentDetails__title {
    font-weight: bold;
    display: block;
}

.appointmentDetails__description,
.appointmentDetails__linkList {
    font-weight: normal;
    display: block;
}

.appointmentDetails__linkList>*:first-child::before {
    content: "(";
}

.appointmentDetails__linkList>*:last-child::after {
    content: ")";
}

.appointmentDetails__linkItem+.appointmentDetails__linkItem::before {
    content: "-";
    padding: 0 5px;
}

.confirmationFooter__title {
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 var(--spacing--small);
}

.confirmationFooter__contentList {
    list-style: disc;
    /* reduce default list spacing */
    margin: 0;
    padding-inline-start: 20px;
}

.confirmationFooter__contentItem+.confirmationFooter__contentItem {
    margin-top: var(--spacing--small);
}

.confirmationFooter_footNote {
    margin: 0;
}

@media (max-width: 500px) {
    .confirmationContainer {
        --spacing--large: 30px;
    }
}
