.ratingInput {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.ratingButton {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0;
    padding-top: 5px;
    background: none;
    border: none;
    transition: all 0.3s;
    cursor: pointer;
}

.ratingInput .ratingButton.frown:hover, .ratingButton.frown.selected {
    color: #F22B29;
}

.ratingButton.meh:hover, .ratingButton.meh.selected {
    color: #EEC643;
}

.ratingButton.smile:hover, .ratingButton.smile.selected {
    color: #589E2B;
}

@media(max-width: 600px) {

    .ratingInput .ratingButton {
        font-size: 12px;
    }

    .ratingInput .ratingButton svg {
        width: 18px;
        height: 18px;
    }

}
