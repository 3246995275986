.trackList {
    display: flex;
    align-items: center;
}

.trackItem {
    display: flex;
    align-items: center;
}

.trackItem .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 25px;
    height: 25px;
    border: 2px solid var(--lighter-grey-color);
    border-radius: 50%;
    font-size: 13px;
    color: var(--dark-grey-color);
    cursor: pointer;
    transition: all 0.3s;
}

.trackItem.done .circle {
    border-color: var(--dark-grey-color);
}

.trackItem.hasError .circle {
    border-color: var(--error-red-color);
    color: var(--error-red-color);
}

.trackItem.active .circle,
.trackItem .circle:hover {
    color: var(--primary-black-color);
    border-color: var(--primary-black-color);
}

.trackItem .dash {
    display: block;
    height: 2px;
    width: 15px;
    background: var(--lighter-grey-color);
    transition: all 0.3s;
}

.trackItem.active .dash,
.trackItem.done .dash {
    background: var(--dark-grey-color);
}

.trackItem:first-of-type .dash {
    display: none;
}
