.button {
    text-transform: uppercase !important;
    background: none;
    border: none;
    cursor: pointer;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    transition: all 0.3s;
}

.button:hover {
    text-decoration: underline;
}