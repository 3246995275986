.list {
    /* Reset default list styling */
    list-style-type: none;
    margin-inline-start: 0;
    padding-inline-start: 0;
    line-height: 1.5;
}

.list__item {
    display: flex;
    align-items: center;
}
.list__item + .list__item {
    margin-top: 0.7em;
}

/* Using ::before rather than ::marker to be able to set a size and a spacing with the item text */
.list__item::before {
    content: url(../../assets/images/grey-check.svg);
    width: 1.5em;
    height: 1em;
    margin-right: 0.7em;
}
.list__item--no-marker::before {
    display: none;
}
