.holder .field {
    width: 100%;
    background: none;
    height: 48px;
    box-sizing: border-box;
    border: 1px solid var(--light-grey-color);
    font-family: 'Poppins';
    font-size: 16px;
    transition: all 0.3s;
}

.holder .field {
    position: relative;
}

.holder .field input, .holder .field textarea {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    background: none;
    border: none;
    padding: 0 20px;
    font-family: 'Poppins';
    font-size: 16px;
    color: var(--primary-black-color);
    position: absolute;
}

.holder .field textarea {
    padding: 12.5px 20px;
    resize: none;
}

.holder .field .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 100%;
    width: 48px;
    position: absolute;
    right: 0;
    cursor: pointer;
}

.holder:hover .field, .holder .field.focused {
    border: 1px solid var(--primary-black-color);
}

.holder.hasError .field {
    border: 1px solid var(--error-red-color);
}

.holder .field input:focus, .holder .field textarea:focus {
    outline: none !important;
}

.holder .field.disabled {
    opacity: 0.5;
    background-color: var(--light-grey-color);
    border: 1px solid var(--primary-black-color);
}

.holder .field input:disabled, .holder .field textarea:disabled {
    cursor: not-allowed;
}

.holder label {
    display: block;
    color: var(--error-red-color);
    font-weight: 400;
    font-size: 14px;
}

@keyframes fadeIn {
    0% {
        height: 0%;
        opacity: 0;
    }

    100% {
        height: auto;
        opacity: 1;
    }
}

.moreInfo {
    margin: 8px 0px;
    opacity: 0;
    animation: fadeIn 0.5s forwards;
    font-size: 12px;
    line-height: 1.5;
}

@media (max-width: 600px) {
    .holder .field textarea {
        font-size: 12px;
    }
}