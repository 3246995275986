form {
  width: 100%;
}

.select {
  width: 100%;
  height: 48px !important;
}

.select .ant-select {
  height: 48px !important;
}

.select .ant-select-selector {
  border-radius: 0px !important;
  padding: 0 20px !important;
  border: 1px solid var(--light-grey-color) !important;
}

.select .ant-select-selection-placeholder {
  font-size: 16px;
  font-family: "Poppins";
  color: var(--primary-black-color);
  opacity: 0.6;
}

.flex {
  display: flex;
  flex-direction: column;
}

.ctaContainer {
  width: 100%;
  display: flex;
  gap: 16px;
  margin-top: 20px;
}

.ctaButton {
  width: 50% !important;
}
