.widerModal {
    width: 800px;
    max-height: 80vh;
}
@media (max-width: 600px) {
    .widerModal {
        max-height: 70vh;
    }
}

.content {
    padding: 20px 30px 0;

    display: flex;
    flex-direction: column;

    gap: 38px;
}
@media (max-width: 900px) {
    .content {
        padding: 20px 0 0;
    }
}

.header {
    display: flex;
    flex-direction: column;

    color: var(--primary-black-color);
    text-align: center;
    font-family: Poppins;
    line-height: 1.5;
}

.title {
    font-size: 24px;
}

.subtitle {
    font-size: 30px;
}

.reviews {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
}

.reviews > * {
    max-width: 45%;
    box-sizing: border-box;
    flex-grow: 1;
    flex-shrink: 1;
}

@media (max-width: 900px) {
    .reviews {
        flex-direction: column;
        gap: 15px;
    }
    .reviews > * {
        max-width: 100%;
    }
}

.actions {
    max-width: 260px;
    width: 100%;
    align-self: center;
}
