.holder .inner {
    display: flex;
}

.holder .inner .daySelect {
    flex: 0.27;
}

.holder .inner .monthSelect {
    margin-right: 15px;
    flex: 0.48;
}

.holder .inner .yearSelect {
    margin-right: 15px;
    flex: 0.27;
}

.holder .inner .monthSelectWithoutDate {
    margin-right: 15px;
    flex: 0.50;
}

.holder .inner .yearSelectWithoutDate {
    margin-right: 15px;
    flex: 0.50;
}

.holder .error {
    display: block;
    color: var(--error-red-color);
    font-weight: 400;
    font-size: 14px;
}

@media (max-width: 800px) {

    .holder .inner {
        flex-direction: column;
    }

    .holder .inner .yearSelect, .holder .inner .monthSelect, .holder .inner .daySelect {
        margin-right: 0px !important;
        margin-bottom: 10px;
        flex: 1;
    }
    
}