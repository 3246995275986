.upfrontPaymentsSection {
    line-height: 1.5;
}

.upfrontPaymentsSection__title {
    text-align: center;
}

.upfrontPaymentsSection__total {
    display: block;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
}

.upfrontPaymentsSection__chargeWarning {
    font-size: 14px;
    text-align: center;
}
.upfrontPaymentsSection__timeEstimateWarning {
    font-size: 14px;
    text-align: center;
    font-style: italic;
}
