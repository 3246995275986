.holder {
    position: relative;
}

.holder input:read-only {
    cursor: pointer;
}

.holder input:disabled {
    cursor: not-allowed;
}

.holder .keepTyping, .holder .dataHolder, .holder .noData {
    position: absolute;
    box-shadow: var(--drop-shadow);
    box-sizing: border-box;
    background-color: white;
    width: 100%;
    padding: 10px 0px;
    margin-top: 10px;
    border: 1px solid var(--light-grey-color);

    pointer-events: none;
    opacity: 0;
    transform: translateY(-10px);
    transition: all 0.3s;
    z-index: 10;

    max-height: 200px;
    overflow-y: auto;
}

.holder .keepTyping, .holder .noData {
    padding: 20px 0px;
    text-align: center;
    font-size: 14px;
}

.holder.focused .keepTyping, .holder.focused .dataHolder, .holder.focused .noData {
    pointer-events: all;
    opacity: 1;
    transform: translateY(0px);
}

.holder .dataHolder .option {
    height: 50px;
    padding: 0px 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: all 0.2s;

    font-size: 16px;
}

.holder .dataHolder .option.active {
    background-color: var(--lighter-grey-color);
}
