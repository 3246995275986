.banner--container {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    background-color: white;
    border-radius: 25px;
    flex-direction: column;
    gap: 10px;
    padding: 25px;
}

.banner--header {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    flex-direction: row;
    gap: 10px;
}

.banner--title {
    flex: 1 0 0;
    color: black;
    font-weight: 700;
}

.banner--body {
    margin-block: 0;
    padding-inline-start: 24px;
}

.banner--icon {
    height: 22px;
}

.banner--cost-container {
    display: flex;
    flex-direction: column;
    line-height: 1.5;
}

.banner--cost-description {
    font-size: 10px;
}

.banner--cost-highlight {
    display: inline;
    background-color: #FBF305;
}
