.calendar-and-loading-wheel-container {
    position: relative;
}

.loading-wheel-visible {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
}

.loading-wheel-hidden {
    display: none;
}

.loading-wheel-icon {
    width: 100px;
    height: 100px;
}

.calendar-overwrite {
    width: 100%;

    font-family: "Poppins" !important;
    font-size: 24px;
    font-weight: 300;

    background: none;
    border: 1px solid var(--light-grey-color);
}

.calendar-overwrite abbr {
    text-decoration: none;
}

.calendar-overwrite .disabled {
    opacity: 0.5;
}

.calendar-overwrite.hidden {
    visibility: hidden;
}

.react-calendar__navigation {
    margin-bottom: 0px;
    margin-top: 10px;
}

.react-calendar__navigation__label__labelText {
    font-size: 16px;
    text-transform: capitalize;
    color: black;
}

.react-calendar__navigation__arrow:disabled {
    opacity: 0.4;
    background: none !important;
}

.react-calendar__tile {
    font-size: 16px;
    border: 1px solid transparent !important;
    color: var(--primary-black-color);
    margin-bottom: 0 !important;
}

.react-calendar__tile:disabled {
    opacity: 0.3;
    background: none;
}

.react-calendar__tile--active,
.react-calendar__tile:focus {
    border: 1px solid var(--primary-black-color) !important;
    background: none !important;
}

.react-calendar__tile--now {
    background-color: rgba(18, 165, 241, 0.5) !important;
}

.calendar-day-status {
    margin: auto;
    margin-top: 5px;
    padding: 3px;
    width: 5px;
    max-width: 5px;
    border-radius: 50%;
    background-clip: padding-box;
}

.calendar-day-status.open {
    background: #31933f;
    border: 2px solid rgba(49, 147, 63, 0.3);
    background-clip: padding-box;
}

.calendar-day-status.blocked {
    background: var(--error-red-color);
    border: 2px solid rgba(238, 7, 90, 0.3);
    background-clip: padding-box;
}

@media (max-width: 800px) {
    .calendar-overwrite {
        font-size: 16px;
    }

    .react-calendar__tile {
        font-size: 14px;
    }
}
