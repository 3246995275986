.picker {
  display: flex;
  height: 48px;
  padding-left: 20px;
  border-radius: 0;
  background: none;
  border-color: var(--light-grey-color);
}

/*
 * I guess you noticed the amount of !important in this file.
 * It turns out that, even when Ant Design exposes `className` variables so we
 * can customize their components, we still have to fight to have the custom
 * CSS properly applied.
 */
.picker input {
  color: var(--primary-black-color) !important;
  font-family: "Poppins" !important;
  font-size: 16px !important;
}

.picker input::placeholder {
  color: var(--dark-grey-color) !important;
}

.picker:hover,
.picker:global(.ant-picker-focused) {
  border-color: var(--primary-black-color) !important;
  background: none !important;
}

.picker svg {
  color: var(--primary-black-color) !important;
}

.popup > div {
  background-color: var(--lighter-grey-color) !important;
  border-radius: 0 !important;
}

.popup > div :global(.ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled) .ant-picker-cell-inner) {
  background: var(--success-green-color) !important;
}

.popup > div :global(.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before) {
  border-color: var(--success-green-color) !important;
}
