.serviceSelect__cardGroup {
    /* Reset default fieldset styling */
    border: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 15px;

    margin-bottom: 35px;
}

.serviceSelect__legend {
    /* Reset default fieldset styling */
    padding: 0;

    display: block;
    text-align: center;
    margin-bottom: 35px;
}

.serviceCard {
    font-weight: normal;
    border: solid 1px var(--light-grey-color);
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    --input-size: 24px;
    --input-gap: 10px;
}
.serviceCard:has(:focus) {
    background-color: var(--light-grey-color);
}
.serviceCard:has(:checked) {
    background-color: #d9d9d9;
}

.serviceCard__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--input-gap);
}

.serviceCard__radioButton {
    margin: 0;
    width: var(--input-size);
    height: var(--input-size);
    accent-color: #fff;
    appearance: none;
    -webkit-appearance: none;
    border-radius: 50%;
    background: white;
    border: 1px solid var(--light-grey-color);
    flex-shrink: 0;
    flex-grow: 0;
}
.serviceCard__radioButton:focus,
.serviceCard__radioButton:focus-visible,
.serviceCard__radioButton:checked {
    /* The outline will be the outer circle */
    outline: 5px solid black;
    outline-offset: -5px;
}

.serviceCard__title {
    font-size: 18px;
    font-weight: bold;
}

.serviceCard__content {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1.5;
}

/* Hide description unless radio is selected */
.serviceCard__accordion {
    margin-left: calc(var(--input-size) + var(--input-gap));
    opacity: 0;
    display: none;
    transition: opacity 0.2s, display 0.2s allow-discrete;
}
:has(:focus) + .serviceCard__accordion,
:has(:checked) + .serviceCard__accordion {
    opacity: 1;
    display: block;
    transition: opacity 0.2s, display 0.2s allow-discrete;
}
