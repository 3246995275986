svg.canceled {
  opacity: 0.3;
}

.left {
  width: 200px;
}

.left button {
  width: 100%;
}

.dateHolder {
  margin: auto;
  position: relative;
  width: 140px;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.clinicHolder h4 {
  margin-bottom: 5px;
  text-align: center;
}

.clinicHolder .address {
  width: 100%;
  font-size: 12.5px;
  line-height: 180%;
  text-align: center;
  display: block;
  margin-bottom: 20px;
}

.dateHolder.canceled {
  opacity: 0.3;
}

.dateHolder svg {
  width: 100%;
  height: 100%;
  position: absolute;
  stroke-width: 0.5px;
}

.dateHolder .day {
  font-size: 30px;
  margin-top: 50px;
}

.dateHolder .what {
  font-size: 40px;
}

.right {
  flex: 1;
  margin-left: 30px;
}

.infoHolder {
  padding: 25px;
  text-align: center;
  border: 1px solid var(--primary-black-color);
  margin-bottom: 20px;
}

.infoHolder p {
  margin-top: 0;
  margin-bottom: 10px;
}

@media (max-width: 700px) {
  .left {
    width: 100%;
    margin-bottom: 30px;
  }

  .right {
    margin-left: 0px;
  }
}
