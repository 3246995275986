.footerContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: auto;
    margin-bottom: 60px;
}

.footer {
    width: 60%;
    background-color: var(--lighter-grey-color);
}

.footer .row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: .3em;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 18px;
}

.footer a {
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline !important;
}

/** MOBILE **/

@media (max-width: 700px) {

    .footerContainer {
        margin-bottom: 0;
    }

    .footer {
        width: 100%;
        padding: 20px;
    }

}