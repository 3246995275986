.holder {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    scroll-snap-align: center;

}

.stickyBottom {
    position: sticky;
    bottom: 0;

    width: 100%;
    background: white;
    margin-top: 30px;
    z-index: 20;
}

.block {
    scroll-margin-top: var(--top-padding);
    position: relative;
    height: 400px;
    box-sizing: border-box;
    transform: scale(0.90);
    opacity: 0.5;
    transition: all 0.2s;
    border: 1px solid transparent;
    scroll-snap-type: y mandatory;
}

.block.focused {
    transform: scale(1);
    opacity: 1;
    z-index: 1;
}

.block button {
    margin-top: 15px;
}

.errorsHolder {
    margin-top: 20px;
    text-align: right;
}

.errorsHolder a {
    display: block;
    font-size: 14px;
    color: var(--error-red-color);
}

.trackerScroll {
    margin: auto;
    /* Allow scrollbar when child is wider than container */
    overflow-x: auto;
    width: fit-content;
    max-width: 100%;
    /* Move scrollbar down */
    padding-bottom: 20px;
    margin-bottom: -20px;
}
