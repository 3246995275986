.delivery.inactive p {
  max-width: 700px;
}

.columnLayout {
  display: flex;
  flex-direction: column;
  gap: 3vh;
}

.loader {
  margin: 56px 48vw 0px 48vw;
}

.pastDeliveriesHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 800px) {
  .nextDeliveryBodyContainer {
    width: 80%;
  }
}
