.submitButton {
  width: auto;
  font-size: 14px;
  background-color: #000;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.datePicker {
  min-width: 200px;
}

.quantityDisclaimer {
  width: 70%;
}

.contentSection {
  width: 60%;
}

.headerButton {
  width: 160px;
  font-size: 13px;
  padding: 0;
  min-height: 40px;
  height: 40px;
}

.headerButton div {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .contentSection {
    width: 80%;
  }
  .titleContainer h2 {
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .contentSection {
    width: 100%;
  }
}
