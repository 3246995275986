.tabHeader {
  display: flex;
  justify-content: space-between;
  padding: 10px 16px;
  background: #f0f0f0;
  color: #bfbfbf;
  border-radius: 2px 2px 0 0;
  border: 1px solid #d9d9d9;
  border-bottom: none;
}

.deliveryDate {
  flex: 2.7;
  text-align: left;
}

.status {
  flex: 3;
  text-align: left;
}

.address {
  flex: 4;
  text-align: left;
}

.actions {
  flex: 1;
  text-align: left;
}

@media (max-width: 600px) {
  .tabHeader {
    display: none;
  }
}
