.cardContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cardContainer .cardContainerTitle{
    margin: 0;
}

.cardContainer div.infoContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.cardContainer div.infoContainer p {
    font-size: 14px;
    margin: 0;
}

.infoContainer .clinicCardHeader{
    display: flex;
    align-items: center;
    text-wrap: nowrap;
}

.laboratoryNote__span {
    color: black;
    font-size: 14px;
}

@media (max-width: 500px) {
    .infoContainer .clinicCardHeader {
        flex-direction: column;
        align-items: flex-start;
    }

    .infoContainer .clinicCardHeader h3 {
        margin-bottom: 5px;
    }
}
