.holder {
    width: 100%;
    position: relative;
    cursor: pointer;
    border: 1px solid var(--light-grey-color);
    box-sizing: border-box;
    overflow: hidden;
    height: 100%;
    max-height: 48px;
    transition: max-height 0.5s;
}

.holder.disabled {
    cursor: not-allowed;
}

.holder .selectedTime {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 20px;
    opacity: 1;
    z-index: 1;
    width: 100%;
    background-color: none;
}

.holder.isActive .selectedTime {
    opacity: 0;
    pointer-events: none;
}

.holder.loading .selectedTime {
    opacity: 1;
    justify-content: center;
    padding-left: 0;
}

.holder.disabled .selectedTime {
    color: var(--dark-grey-color);
}

.holder.isActive {
    /* max-height: 100%; */
}

.holder .list {
    overflow-y: scroll;
    max-height: 100%;
    opacity: 0;
    transition: opacity 0.5s;
}

.holder.isActive .list {
    opacity: 1;
}

.holder .list .time {
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border-bottom: 1px solid var(--light-grey-color);
}

.holder .list .time:hover {
    background-color: #e6e6e6;
}

.holder .list .time:last-of-type {
    border-bottom: none;
}

@media (max-width: 800px) {

    .holder .selectedTime {
        padding-left: 0px;
        justify-content: center;
    }

}