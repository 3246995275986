.page {
    animation-name: slide-in-left;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.consentModal {
    width: 850px;
}

@keyframes slide-in-left {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
