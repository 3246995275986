.deliverySettingsForm {
  max-width: 768px;
}

.mt20 {
  margin-top: 20px;
}

.warning {
  color: var(--light-grey-color);
  font-size: 14px;
  line-height: 20px;
}
