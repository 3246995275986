.holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}

.holder>div {
    display: flex;
    align-items: center;
}

.holder.positive>div {
    color: var(--success-green-color);
}

.holder.refused>div {
    color: var(--error-red-color);
}

.holder.retake>div {
    color: var(--warning-yellow-color);
}

.iconHolder {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
    background: white;
    border: 1px solid var(--light-grey-color);
    border-radius: 50%;
    padding: 4px;
    box-sizing: border-box;
}