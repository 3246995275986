.breadcrums {
    background: white;
    padding: 15px 0;
}

.crum {
    display: inline-flex;
    align-items: center;
    padding: 0;
    margin-right: 50px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    background: none;
    border: none;

    font-family: 'Poppins';
    font-weight: 400;
    color: var(--primary-black-color);
    font-size: 14px;
    line-height: 160%;
}

.crum svg {
    height: 15px;
    margin-left: 10px;
}

.crum:last-of-type svg {
    display: none;
}

.crum:hover,
.breadcrums .crum:active,
.breadcrums .crum.active {
    text-decoration: underline;
}

.crum:disabled {
    opacity: 0.5;
    pointer-events: none;
}

@media (max-width: 800px) {

    .crum {
        margin-right: 20px;
    }

}
