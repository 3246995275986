.holder {
    width: 100%;
    box-sizing: border-box;
}

.containerOverride {
    max-width: 100% !important;
    margin: 0 !important;
}

.header {
    display: flex;
    overflow-x: auto;
    box-sizing: border-box;
    margin: auto;
    max-width: 1060px;
    padding: 0 20px;
}

.header button {
    background: none;
    border: none;
    margin: 15px 0;
    padding: 10px 0;
    display: block;
    margin-right: 50px;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    outline: none;

    font-size: 18px;
    font-weight: 700;
    color: var(--light-grey-color);

    transition: all 0.3s;
}

.header button:last-of-type {
    margin-right: 0;
}

.header button h4 {
    margin: 0;
}

.header button:not(.disabled):hover,
.header button:not(.disabled):active,
.header button.active {
    color: var(--primary-black-color);
}

.header button.active {
    border-bottom: 1px solid var(--primary-black-color);
}

.header button.disabled {
    cursor: not-allowed;
}

@media (max-width: 600px) {

    .header button {
        margin-right: 30px;
    }

    .header h4 {
        font-size: 16px;
    }

}
