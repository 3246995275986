.card {
  border: 1px solid var(--primary-black-color);
  margin-bottom: 30px;
}

.card a {
  font-size: 16px;
}

.card button {
  display: inline-flex;
  height: auto !important;
  min-height: 40px;
  width: auto;
  max-width: 100%;
  padding: 5px 15px;
}

.header {
  min-height: 70px;
  border-bottom: 1px solid var(--primary-black-color);
  padding: 15px 25px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background: rgb(255, 255, 255, 0.7);
}

.header svg {
  min-width: 30px;
  min-height: 30px;
  margin-right: 20px;
}

.header h2 {
  margin: 0;
  font-size: 22px;
}

.header p {
  margin: 0;
  margin-top: 5px;
}

.content{
  padding: 25px;
}

.body {
  display: flex;
}

.body p {
  margin-top: 0;
}

@media (max-width: 700px) {
  .body {
    flex-direction: column;
  }
}
