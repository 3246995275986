span.current {
  font-weight: bold;
}

span.future {
  opacity: 0.25;
}

span.pharmacist {
  font-style: italic;
}