.divider {
    border-bottom: 1px solid #FFF;
    background-color: #DADADA;
    height: 1px;
    margin: 0.5em 0 1.5em;
}

.divider span {
    display: block;
    width: 50px;
    height: 1px;
    background-color: var(--primary-black-color);
}

.clinicCardContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 32px 0;
    border-top: 1px solid #BFBFBF;
}

.clinicListContainer{
    margin-top: 5rem;
}

.clinicListContainer > div:last-child .clinicCardContainer {
    border-bottom: 1px solid #BFBFBF;
}

.clinicCardContainer svg {
    margin-top: 15px;
}

.enter {
    max-height: 0;
    opacity: 0;
}

.paddingTop {
    box-sizing: border-box;
}

.enterActive {
    max-height: 454px;
    padding-top: 1.5rem;
    transition: max-height 0.5s;
}

.enterDone {
    display: flex;
    flex-direction: column;
    gap: 35px;
    opacity: 1;
}

.exit {
    max-height: 454px;
    padding-top: 1.5rem;
}

.exitActive {
    max-height: 0;
    padding-top: 0;
    opacity: 0;
    transition: max-height 0.5s, padding-top 0.5s;
}

.loadingIconContainer {
    text-align: center;
}

.feeDisclaimer__paragraph {
    color: black;
    text-align: center;
    font-size: 14px;
    margin: 0;
}
