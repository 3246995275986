.checkbox {
    display: flex;
}

.checkbox button {
    border: 2px solid var(--primary-black-color);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    min-width: 16px;
    max-width: 16px;
    height: 16px;
    padding: 0;
    margin-top: 5px;
    margin-right: 15px;
    flex: 1;
}

.checkbox button>div {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--primary-black-color);
}

.checkbox button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.checkbox label {
    margin: 0px;
}