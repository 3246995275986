.card-container {
    display: flex;
    align-items: center;
    border: 1px solid #B4B4B4;
    background: #FFFFFF;
    height: 45px;
}

.card-container__item-main {
    flex: 3.5 0 auto;
    padding-left: 5px;
}

.card-container__item {
    flex: 1 0 auto;
    padding-left: 5px;
}

.card-container__item-end {
    flex: 0.65 0 auto;
    padding-right: 5px;
}

.payment-options {
    font-size: 16px;
    text-align: center;
    line-height: inherit;
    margin: 20px 0;
}

.payment-options a {
    font-size: inherit;
    line-height: inherit;
    text-decoration: underline !important;
}

.single-line {
    display: flex;
    gap: 5px;
    justify-content: center;
}

.save-button {
    width: 100%;
}

.ramqWarning {
    padding-top: 8px;
    display: flex;
    align-items: center;
}

.ramqWarning a {
    text-decoration: underline !important;
}
