.container {
  width: 100%;
}
.container .inner {
  display: flex;
}

.container .inner .addressField {
  margin-right: 15px;
  flex: 0.27;
}

.container .inner .cityField {
  margin-right: 15px;
  flex: 0.48;
}

.container .inner .postalCodeField {
  flex: 0.27;
}

@media (max-width: 800px) {

  .container .inner {
      flex-direction: column;
  }

  .container .inner .addressField, .container .inner .cityField, .container .inner .postalCodeField {
      margin-right: 0px !important;
      margin-bottom: 10px;
      flex: 1;
  }
  
}