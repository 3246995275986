.loader-container {
    height: 83vh;
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.loader svg {
    margin-right: 10px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    flex-direction: column;
    position: relative;
    margin-top: 8vh;
    text-align: center;
}

.header-title {
    font-size: 36px;
    font-weight: 500;
    margin: 40px 0 0 0;
}

.navigation p {
    font-size: 28px;
    margin-bottom: 40px;
}

.navigation div {
    display: flex;
    justify-items: center;
    margin: auto;
}

.navigation a {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
}

.navigation div button {
    background: none;
    border: none;
    margin: 0 30px;
    cursor: pointer;
    transition: all 0.3s;
}

.navigation div button h3 {
    font-weight: 300;
    word-break: break-word;
}

.navigation div a.disabledLink {
    position: relative;
}

.navigation div a.disabledLink button div::after {
    content: attr(disabled-tool-tip);
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 5px;
    border: 2px solid var(--primary-black-color);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, .9);
    color: black;
    text-align: center;
    z-index: 2;
    font-size: 1rem;
    transform: scale(0);
    transition: background-color ease-out 150ms,
        transform ease-out 150ms,
        bottom ease-out 150ms;
}

.navigation div a.disabledLink button div:hover::after,
.navigation div a.disabledLink button:hover div::after {
    transform: scale(1);
    bottom: 30%;
}

@supports ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {

    .navigation div a.disabledLink button div::after,
    .navigation div a.disabledLink button:hover div::after {
        background-color: unset;
        -webkit-backdrop-filter: blur(2em);
        backdrop-filter: blur(2em);
        transition:
            backdrop-filter ease-in-out 150ms,
            transform ease-out 150ms 150ms,
            bottom ease-out 150ms 150ms;
    }

    .navigation div a.disabledLink button div:hover::after {
        backdrop-filter: blur(2em);
        transform: scale(1);
        bottom: 30%;
    }
}

.navigation div button:hover {
    transform: scale(1.1);
}

.navigation div button:focus {
    outline: none;
}

.navigation div button img {
    height: 90px;
    width: 100%;
}

.tabs-header {
    display: flex;
    background: transparent;
    justify-items: center;
}

.breadcrumbs {
    background: transparent;
    padding: 15px 0;
    text-align: center;
}

@media (max-width: 800px) {
    .header {
        margin: 40px 20px 0 20px;
        line-height: 40px;
    }

    .navigation div button {
        margin: 0;
        padding: 0;
    }

    .navigation div button img {
        height: 50px;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;
    }

    .navigation div button h3 {
        font-size: 14px;
        font-weight: 300;
        word-break: break-word;
    }
}

@media (max-width: 360px) {
    .header {
        line-height: 40px;
    }
}
