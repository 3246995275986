.holder {
    display: flex;
    height: auto;
}

.calendarCol {
    flex: 0.8;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.calendarHolder {
    margin-top: 10px;
    flex: 1;
}

.timeCol {
    display: flex;
    flex: 0.2;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: hidden;
}
