.buttonSelector button {
    border: 1px solid var(--light-grey-color);
    height: 40px;
    padding: 0 20px;
    font-size: 16px;
    background-color: none;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 0 !important;
    cursor: pointer;
    transition: all 0.3s;
    outline: none;
}

.buttonSelector button:hover, .buttonSelector button:focus {
    background-color: var(--light-grey-color);
    border: 1px solid var(--primary-black-color);
}

.buttonSelector button:disabled {
    opacity: 0.7;
    pointer-events: none;
}

.buttonSelector button.selected {
    background-color: var(--primary-black-color);
    border: 1px solid var(--primary-black-color);
    color: white;
}

@media (max-width: 800px) {

    .buttonSelector button {
        height: 30px;
        padding: 0 10px;
        font-size: 14px;
        margin-right: 7px;
        margin-bottom: 7px;
    }

}
