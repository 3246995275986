.tagStyle {
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: inherit;
    max-width: 9rem;
    font-size: 14px;
}

.dotStyle {
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
    margin-right: 10px;
}

@media (max-width: 800px) {
    .tagStyle {
        min-width: 18vw;
    }
}