.container {
    max-width: 480px !important;
}

.container h2 {
    margin-bottom: 5px;
}

.container .imgHolder {
    text-align: center;
}

.container img {
    max-width: 120px;
    margin-bottom: 0px;
}

.container a {
    display: block;
    margin-bottom: 10px;
    text-decoration: underline !important;
}

.consent button {
   border-radius: 0%;
}

.consent button>div {
   border-radius: 0%;
}

.consent a {
    display: inline;
    font-size: 16px;
}

.input-label-hint {
    float: inline-end;
}

.subtitle  {
    font-size: 16px;
    text-align: center;
    line-height: inherit;
    margin-right: 5px;
}

.single-line {
    display: flex;
    gap: 5px;
    justify-content: center;
}

.preauth-container  h2 {
    margin-bottom: 5px;
}

.separator {
    margin: 40px 0;
}
