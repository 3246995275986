.satisfactionQuestionnaire .questionBlock {
    text-align: left;
    margin-top: 15px;
    margin-bottom: 15px;
}

.divider {
    border-bottom: 1px solid #FFF;
    background-color: #DADADA;
    height: 1px;
    margin: 0.5em 0 1.5em;
}

.divider span {
    display: block;
    width: 50px;
    height: 1px;
    background-color: var(--primary-black-color);
}

@media (max-width: 600px) {
    .questionBlock p {
        font-size: 12px;
    }
}