.deliveryBodyContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
}

.dateDisabled {
  text-decoration: line-through;
  color: #00000040;
}

.customRow span {
  margin-left: 5px;
}

.buttonsContainer {
  display: flex;
  gap: 2rem;
}

.panelHeader {
  display: flex;
  align-items: center;
  margin: 0.1vh 1vw;
  border-radius: 8px;
  gap: 12px;
}

.date {
  flex: 1;
}

.address {
  flex: 2;
}

.status {
  flex: 1.5;
}

sup {
  font-size: 10px;
}

.prelibRow {
  flex-flow: nowrap;
}

@media (max-width: 900px) {
  .deliveryBodyContainer {
    width: 60%;
  }
}

@media (max-width: 800px) {
  .deliveryBodyContainer {
    width: 80%;
  }
}

@media (max-width: 600px) {
  .address {
    display: none;
  }

  .deliveryBodyContainer {
    width: 100%;
  }
}
