
.notificationCard {
    margin-top: 40px;
    background-color: white;
    border-radius: 1.5rem;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    padding: 1rem 0.5rem 1rem;
    max-width: 28rem;
  }
  
  .notificationContent {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
  
  .notificationIcon {
    font-size: 1.5rem;
  }
  
  .notificationText {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  
  .notificationTextTitle {
    text-wrap: nowrap;
    font-weight: 900;
    font-size: clamp(0.8rem, 3.5vw, 1rem); 
    margin: 0;
  }
  
  .notificationTextSubtitle {
    color: #4B5563;
    margin: 0;
    font-size: clamp(0.8rem, 3.5vw, 1rem); 
    line-height: 1rem;
  }
  
  .notificationIconImage {
    width: 2.5rem;
    height: auto;
  }

  .resendCodeContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 2rem 0;
  }

  .resendCodeButton{
      border: none;
      font-size: 1rem;
      text-decoration: underline;
      color: #000;
      opacity: 0.5;
  }

  .resendCodeButton:hover{
    cursor: pointer;
    opacity: 1;
  }

  .resendCodeButton:disabled{
    cursor: not-allowed;
    opacity: 0.5;
  }