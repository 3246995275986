.holder .field {
    cursor: text;
    position: relative;
    border: 1px solid var(--light-grey-color);
    width: 100%;
    padding: 0 20px;
    padding-bottom: 7px;
    height: auto;
    box-sizing: border-box;
    transition: all 0.3s;
}

.holder:hover .field {
    border: 1px solid var(--light-grey-color);
}

.holder .field.focused {
    border: 1px solid var(--primary-black-color);
}

.holder .field.disabled {
    opacity: 0.5;
    background-color: var(--light-grey-color);
    border: 1px solid var(--primary-black-color);
}

.holder.hasError .field {
    border: 1px solid var(--error-red-color);
}

.holder .field .cell {
    box-sizing: border-box;
    font-size: 16px;
    color: var(--primary-black-color);
    cursor: default;
    height: 35px;
    display: inline-flex;
    align-items: center;
    border: 1px solid var(--light-grey-color);
    padding: 0 0 0 10px;
    margin-top: 7px;
    margin-right: 10px;
    border-radius: 4px;
}

.holder .field .cell svg {
    width: 16px;
    height: 100%;
    padding: 5px;
    color: var(--light-grey-color);
    cursor: pointer;
    transition: all 0.3s;
}

.holder .field .cell svg:hover {
    color: var(--primary-black-color);
}

.holder .field input { 
    box-sizing: border-box;
    height: 35px;
    min-width: 10px;
    margin-top: 7px;
    font-family: 'Poppins';
    font-size: 16px;
    padding:0;
    color: var(--primary-black-color);

    border: none;
    background: none;
}

.holder .field input:focus {
    outline: none !important;
}

.holder .field .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 100%;
    width: 50px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
}

.holder label {
    display: block;
    color: var(--error-red-color);
    margin: 0;
    margin-top: 2px;
}