.card {
    border: solid 1px var(--lighter-grey-color);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 25px;
}


.logo {
    margin: 5px 0 10px;
    height: 20px;
    object-fit: scale-down;
}

.comment {
    flex-grow: 1;
    font-size: 16px;
}

.rating {
    --star-width: 20px;

    display: block;
    background-image: url('../../assets/images/star.svg');
    background-repeat: repeat-x;
    background-position-y: center;

    height: var(--star-width);
    width: calc(5 * var(--star-width));
    max-width: 100%;
    margin: 5px 0px;
}

.user {
    font-size: 14px;
    line-height: 125%;
}

.user__name {
    font-weight: bold;
}
