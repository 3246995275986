.inputHolder button {
    width: unset;
    padding: 10px 20px 10px 10px;
    margin-right: 10px;
}
.inputHolder button:focus {
    background-color: var(--primary-black-color) !important;
}

@media (max-width: 600px) {
    .inputHolder button {
        height: 35px;
    }
}