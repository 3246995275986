.holder a {
 font-size: 16px;
}

.list {
    padding: 0;
}

.list li {
    list-style: none;
    display: flex;
    margin-bottom: 10px;
}

.infoHolder {
    padding: 25px;
    border: 1px solid var(--primary-black-color);
    margin-bottom: 30px;
}

.infoHolder h5 {
    text-align: center;
    margin: 0;
    margin-bottom: 20px;
}

.infoHolder p {
    margin-top: 0;
    margin-bottom: 10px;
}