.checkboxWrapper {
  display: flex;
  align-items: center;
}

.checkboxWrapper input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border: 1px solid #000000;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type="checkbox"]:checked:before {
  content: "\2713";
  display: block;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.checkboxWrapper label {
  font-weight: 400;
}

.CtaRow {
  display: flex;
  gap: 1.8rem;
  margin: 0.7rem 0px 1.5rem 0px;
}

.CtaRow button {
  width: auto;
}

.consent,
.consent > a {
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
}
.container {
  max-width: 60%;
}

@media (max-width: 800px) {
  .container {
    max-width: 100%;
  }
}
